import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import '../css/belowbar.css'

export default function BelowBar() {
    return (
        <MDBFooter bgColor='antiquewhite' className='text-center text-lg-start text-muted'>
            <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
                <div className='me-5 d-none d-lg-block'>
                    <span style={{fontFamily:"Montserrat", fontWeight:"700", marginLeft:"110px"}}>Get connected with us on social networks:</span>
                </div>

                <div>
                    <a target="_blank" style={{fontSize:"20px"}} href='https://www.facebook.com/ducsjmc/' className='me-4 text-reset'>
                        <MDBIcon fab icon="facebook" />
                    </a>
                    <a target="_blank" style={{fontSize:"20px"}} href='https://twitter.com/DUCSJMC' className='me-4 text-reset'>
                        <MDBIcon fab icon="twitter" />
                    </a>
                    <a target="_blank" style={{fontSize:"20px"}} href='https://maps.app.goo.gl/hVm5jknh8hpqxqP88' className='me-4 text-reset'>
                        <MDBIcon fab icon="google" />
                    </a>
                    <a target="_blank" style={{fontSize:"20px"}} href='https://www.instagram.com/dumasscomm/' className='me-4 text-reset'>
                        <MDBIcon fab icon="instagram" />
                    </a>
                    <a target="_blank" style={{fontSize:"20px"}} href='https://www.linkedin.com/school/dibrugarh-university-dibrugarh/?originalSubdomain=in' className='me-4 text-reset'>
                        <MDBIcon fab icon="linkedin" />
                    </a>
                </div>
            </section>

            <section>
                <MDBContainer className='text-center text-md-start mt-5'>
                    <MDBRow className='mt-3'>
                        <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4'>
                            <h6 style={{fontSize:"18px", color:"#0c133b"}} className='text-uppercase fw-bold mb-4'>
                                <MDBIcon fas icon="university" />
                                &nbsp;CSJMC DU
                            </h6>
                            <p style={{fontSize:"16px", fontWeight:"500"}}>
                                &copy;&nbsp;Centre for Studies in Journalism & Mass Communication
                            </p>
                            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3540.636960801562!2d94.89154657545828!3d27.4494226763314!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3740a2b24b33ce25%3A0xbe325df384067cac!2sCentre%20For%20Studies%20In%20Journalism%20And%20Mass%20Communication!5e0!3m2!1sen!2sin!4v1715034703998!5m2!1sen!2sin" width="400" height="300" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                        </MDBCol>
                        <MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4'>
                            <h6 style={{fontSize:"18px", color:"#0c133b"}} className='text-uppercase fw-bold mb-4'>Important Links</h6>
                            <p>
                                <a target="_blank" style={{textDecoration:"none", fontWeight:"600"}} href='https://dibru.ac.in/' className='text-reset' >
                                <i class="fas fa-angle-right"></i>&nbsp;Dibrugarh University
                                </a>
                            </p>
                            <p>
                                <a target="_blank" style={{textDecoration:"none", fontWeight:"600"}} href='https://erp.dibru.work/dibru/student/login' className='text-reset'>
                                <i class="fas fa-angle-right"></i>&nbsp;ERP Portal
                                </a>
                            </p>
                            <p>
                                <a target="_blank" style={{textDecoration:"none", fontWeight:"600"}} href='https://docs.google.com/forms/d/e/1FAIpQLSewaN2-wlaET3fROMTOUrpLsG37Vb5Z-ZY_gy1XPCG7ijENhQ/viewform' className='text-reset'>
                                <i class="fas fa-angle-right"></i>&nbsp;DUAA Portal
                                </a>
                            </p>
                        </MDBCol>

                        <MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4'>
                            <h6 style={{fontSize:"18px", color:"#0c133b"}} className='text-uppercase fw-bold mb-4'>Quick links</h6>
                            <p>
                                <a style={{textDecoration:"none", fontWeight:"600"}} href='/' className='text-reset'>
                                <i class="fas fa-angle-right"></i>&nbsp;Home
                                </a>
                            </p>
                            <p>
                                <a target="_blank" style={{textDecoration:"none", fontWeight:"600"}} href='https://scribehow.com/shared/Join_CSJMC_Alumni_Association_and_Update_Profile__iAp2_DMCRyGKgzFFMkeF_A' className='text-reset'>
                                <i class="fas fa-angle-right"></i>&nbsp;Website Instructions
                                </a>
                            </p>
                            <p>
                                <a target="_blank" style={{textDecoration:"none", fontWeight:"600"}} href='/alumniform' className='text-reset'>
                                <i class="fas fa-angle-right"></i>&nbsp;Alumni Registration
                                </a>
                            </p>
                            <p>
                                <a target="_blank" style={{textDecoration:"none", fontWeight:"600"}} href='https://forms.gle/b6e8wfEJtxntS8uc8' className='text-reset'>
                                <i class="fas fa-angle-right"></i>&nbsp;Report an Error
                                </a>
                            </p>
{/*                             <p>
                                <a style={{textDecoration:"none", fontWeight:"600"}} href='/team-section' className='text-reset'>
                                <i class="fas fa-angle-right"></i>&nbsp;Meet the team
                                </a>
                            </p> */}
                        </MDBCol>

                        <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-md-0 mb-4'>
                            <h6 style={{fontSize:"18px", color:"#0c133b"}} className='text-uppercase fw-bold mb-4'>Contact</h6>
                            <p>
                                <MDBIcon icon="home" className="me-2" />
                                <a href="https://maps.app.goo.gl/wWHJG7H3w6JL9xUZ7" target="_blank" style={{textDecoration:"none", fontWeight:"600", color:"grey"}}>Centre For Studies In Journalism And Mass Communication,
                                    Dibrugarh University, Dibrugarh, Assam 786004</a>
                            </p>
                            <p>
                                <a style={{textDecoration:"none", fontWeight:"600", color:"grey"}} href="mailto:ducsjmc@gmail.com">
                                    <MDBIcon icon="envelope" className="me-3" />
                                    ducsjmc@gmail.com
                                </a>
                            </p>
                            {/* <p>
                                <MDBIcon icon="phone" className="me-3" /> 0373-2370240
                            </p> */}

                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </section>

            <div className='text-center p-3' style={{backgroundColor: '#0c133b'}} id="textbar">
                Developed By: &nbsp;
                <a target="_blank" style={{textDecoration:"none", }} className='text-reset fw-bold' id="nametag" href='https://www.linkedin.com/in/saheb-ullah-05292a258/'>
                    Md Sahebullah
                </a>
                &nbsp;&&nbsp;
                <a target="_blank" style={{textDecoration:"none"}} className='text-reset fw-bold' id="nametag" href='https://www.linkedin.com/in/kalyangupta12/'>
                    Kalyan Gupta
                </a>
            </div>
        </MDBFooter>
    );
}
