import React from "react";
import '../css/teamSection.css'
import KalyanImage from '../images/teams/kalyan.jpg'
const TeamSection = () => {
  return (
    <div className="container text-center mt-5 mb-2">
      <h1 className="mb-0">Meet our team</h1>
      <span>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum
      </span>
      <div className="container mt-3 mb-2">
        <div className="row">
          <div className="col-lg-3 mb-4">
            <div className="bg-white p-3 text-center rounded box">
              <img
                src={KalyanImage}
                alt="wrapkit"
                className="img-responsive rounded-circle"
                width="90"
              />
              <h5 className="mt-3 name">Michael Doe</h5>
              <h6 className="work d-block">Property Specialist</h6>
              <div className="mt-4 about">
                <span>
                  You can rely on our amazing features list and also our
                  customer services will be great experience.
                </span>
              </div>
              <div className="mt-4">
                <h6 className="v-profile">View Profile</h6>
              </div>
            </div>
          </div>
          <div className="col-lg-3 mb-4">
            <div className="bg-white p-3 text-center rounded box">
              <img
                src="https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/team/t2.jpg"
                alt="wrapkit"
                className="img-responsive rounded-circle"
                width="90"
              />
              <h5 className="mt-3 name">Michael Doe</h5>
              <h6 className="work d-block">Property Specialist</h6>
              <div className="mt-4 about">
                <span>
                  You can rely on our amazing features list and also our
                  customer services will be great experience.
                </span>
              </div>
              <div className="mt-4">
                <h6 className="v-profile">View Profile</h6>
              </div>
            </div>
          </div>
          <div className="col-lg-3 mb-4">
            <div className="bg-white p-3 text-center rounded box">
              <img
                src="https://www.wrappixel.com/demos/ui-kit/wrapkit/assets/images/team/t3.jpg"
                alt="wrapkit"
                className="img-responsive rounded-circle"
                width="90"
              />
              <h5 className="mt-3 name">Michael Doe</h5>
              <h6 className="work d-block">Property Specialist</h6>
              <div className="mt-4 about">
                <span>
                  You can rely on our amazing features list and also our
                  customer services will be great experience.
                </span>
              </div>
              <div className="mt-4">
                <h6 className="v-profile">View Profile</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamSection;

