import React from 'react';
import { Container, Row, Col, Card, ListGroup } from 'react-bootstrap';

function About() {
  return (
    <Container className="mt-5">
      <h2 className="text-center mb-4">About the Centre</h2>
      <Row>
        <Col>
          <Card className="shadow mb-4">
            <Card.Body style={{height:"auto"}}>
              <Card.Text>
              The Centre for Studies in Journalism and Mass Communication, Dibrugarh University is one of the leading institutions of higher learning in the field of communication and media studies in India. The Centre offers M.A in Mass Communication (MAMC) course with a provision for specialization in print, electronic, new media and public affairs and a mandatory internship programme. With faculty trained in reputed institutions and well-equipped studios and computer laboratories, it has been successful in carving a niche for itself in the field of Media and Communication education since its inception in 2008. Its state-of-the-art infrastructure provides the students balanced inputs in both theory and practice of mass communication. Eminent scholars, journalists, filmmakers, video professionals, corporate communication specialists, and leading academicians constitute a panel of eminent visiting faculty who offer quality education to the students from across India and those from abroad enrolled under Indian Council for Cultural Relations – African Scholarship Scheme.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Card className="shadow">
            <Card.Body style={{height:"auto"}}>
              <Card.Title className="mb-4">Major Objectives</Card.Title>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <i className="fas fa-check me-2 text-primary"></i>
                  To enable the learner to develop sound theoretical knowledge and professional skills and competencies in media and communication studies.
                </ListGroup.Item>
                <ListGroup.Item>
                  <i className="fas fa-check me-2 text-primary"></i>
                  To train the learners with equipment and infrastructure to understand the converging world of communications and adapt to the latest developments in the field.
                </ListGroup.Item>
                <ListGroup.Item>
                  <i className="fas fa-check me-2 text-primary"></i>
                  To enable the learners to undergo thorough internships that afford them hands-on training and an opportunity to work with the leading professionals of the region and nation.
                </ListGroup.Item>
                <ListGroup.Item>
                  <i className="fas fa-check me-2 text-primary"></i>
                  To enable the learners to implement ethical behaviors in their own professional practice of mass communication and usage of mass media tools.
                </ListGroup.Item>
                <ListGroup.Item>
                  <i className="fas fa-check me-2 text-primary"></i>
                  To enable the learners to receive guidance from a diverse field of faculties with a mix of academic and professional backgrounds to ensure excellence in the field of communication and journalism.
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* <Row className="mb-4">
        <Col>
          <Card className="shadow">
            <Card.Body style={{height:"auto"}}>
              <Card.Title className="mb-4">Mission</Card.Title>
              <Card.Text>
              Our mission is to build a strong alumni community from the Centre for Studies in Journalism and Mass Communication at Dibrugarh University. We strive to connect, collaborate, and provide learning opportunities for our alumni through this platform. By building a strong alumni engagement, we aim to empower our alumni for success in their careers and to make a positive impact on society.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Card className="shadow">
            <Card.Body style={{height:"auto"}}>
              <Card.Title className="mb-4">Vision</Card.Title>
              <Card.Text>
              Our vision is to establish an interactive online platform for alumni of the Centre for Studies in Journalism and Mass Communication, Dibrugarh University. The platform aims to enhance alumni connections, provide career support, mentorship, and knowledge sharing. We strive to create a strong community that promotes achievements, growth, integrity, and excellence in the field of journalism and mass communication.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row> */}
      <Row className="mb-4">
        <Col>
          <Card className="shadow">
            <Card.Body style={{height:"auto"}}>
              <Card.Title className="mb-4">International Collaboration</Card.Title>
              <Card.Text>
                The Centre is currently engaged with UNICEF for the multidisciplinary master's programme on Communication for Sanitation, Hygiene and Health (CSH&H). The Course is the joint initiative of Dibrugarh University and UNICEF designed to address the needs for communication experts in the area of public health promotion. The primary objective is “To give the learners the ability to comprehend and communicate the concepts of Health, Hygiene and Sanitation for acting as competent and professional change agents to transform the communities towards all-round development with the focus on the critical importance of Health, Hygiene and Sanitation.”
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Card className="shadow">
            <Card.Body style={{height:"auto"}}>
              <Card.Title className="mb-4">Departmental Activities</Card.Title>
              <Card.Text>
                As part of its practical exercises, students are assigned to produce radio programmes and documentary films as part of their curriculum. Internship facilities are arranged for the students of the centre in different reputed media organizations in order to provide them with the necessary exposure to various media practices. Award-winning films and documentaries are screened for the students every week. Guest speakers and faculties are invited to interact with students from reputed media organizations and the universities across the country. Workshops and seminars on media are organized from time to time in the centre.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className="shadow">
            <Card.Body style={{height:"auto"}}>
              <Card.Title className="mb-4">Placement</Card.Title>
              <Card.Text>
                The centre has a good placement record. The passed-out students are in service in different public and private sector organizations, media industries, colleges, and educational institutions. Some of the recent recruiters include UNICEF, Indian Oil Corporation, Hindustan Uniliver, APDCL Ltd, All India Radio (AIR), Radio Mirchi, News Live, The Assam Tribune, The Sentinel, GenPac, The Telegraph, All India Radio, Doordarshan, Pratidin Time, DY 365, Aami Asomar Janagan, Prag News, Printland, Digdarshan, Dainadi Barta, Asomiya Pratidin, Assam Talks, Amar Asom, Assam Down Town University, Assam Women University, etc. The centre works in coordination with the Placement Cell of Dibrugarh University.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default About;
