import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AlumniForm() {
  const { isAuthenticated, user } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  const [userExists, setUserExists] = useState(false);

  useEffect(() => {
    if (!isAuthenticated || !user || !user.email) {
      setIsLoading(false);
      return;
    }

    const email = user.email;
    axios
      .get(`https://csjmcdualumni-api.vercel.app/users/check/${encodeURIComponent(email)}`)
      .then((response) => {
        setUserExists(response.data.exists);
      })
      .catch((error) => {
        console.error('Error checking user:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [isAuthenticated, user]);

  useEffect(() => {
    if (userExists) {
      toast.error('You have already submitted the form.');
    } else {
      // Load ElfSight script when userExists is false
      const script = document.createElement('script');
      script.src = 'https://apps.elfsight.com/p/platform.js';
      script.async = true;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [userExists]);

  if (!isAuthenticated) {
    return (
      <Container fluid className="mt-5">
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <p>Please log in to access the form.</p>
          </Col>
        </Row>
      </Container>
    );
  }

  if (isLoading) {
    return (
      <Container fluid className="mt-5">
        <Row>
          <Col md={{ span: 6, offset: 3 }} className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      </Container>
    );
  }

  if (userExists) {
    return (
      <Container fluid className="mt-5">
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <p>You have already submitted the form.</p>
          </Col>
        </Row>
      </Container>
    );
  }

  // If user is authenticated, not loading, and user doesn't exist
  return (
    <Container fluid className="mt-5">
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <div
            className="elfsight-app-238c7391-bd2b-443f-812f-1b94e2a22600"
            style={{ width: '100%', height: '100%' }}
            data-elfsight-app-lazy
          ></div>
        </Col>
      </Row>
    </Container>
  );
}

export default AlumniForm;
