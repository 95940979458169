import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import dulogo from '../images/logodu.png'
import { useAuth0 } from "@auth0/auth0-react";

function NavigationBar() {
  const { loginWithRedirect, logout, isAuthenticated, user } = useAuth0();
  const [activeLink, setActiveLink] = useState(""); // State to track active link

  // Function to handle link click and set active link
  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  return (
    <>
      <style>
        {`
          .navbar {
            position: -webkit-sticky; /* For Safari */
            position: sticky;
            top: 0;
            z-index: 1000; /* Ensure the navbar is above other content */
          }
        `}
      </style>
      <Navbar
        collapseOnSelect
        expand="lg"
        style={{ backgroundColor: "#0c133b" }} // Background color
        variant="dark"
        sticky="top"
      >
        <Container>
          <Navbar.Brand as={Link} to="/">
            <img
              alt=""
              src={dulogo}
              width="60px"
              height="60px"
              className="d-inline-block align-center"
              style={{ borderRadius: "50%", marginRight: "20px" }} // Rounded image with margin
            />
            <span style={{ fontFamily: "Montserrat", fontSize: "1rem", color: "antiquewhite",fontWeight:"700" }}>CSJMC Alumni</span> {/* Brand text styling */}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link
                as={Link}
                to="/"
                onClick={() => handleLinkClick("home")} // Set active link
                style={{
                  fontFamily: "Montserrat",
                  color: activeLink === "home" ? "yellow" : "antiquewhite",
                  fontWeight: activeLink === "home" ? "600" : "",
                  padding: activeLink === "home" ? "10px 20px" : "10px 20px",
                  backgroundColor: activeLink === "home" ? "Blue" : "",
                  borderRadius: activeLink === "home" ? "15px" : "",
                }} // Link styling with conditional color
              >
                Home
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/alumni"
                onClick={() => handleLinkClick("alumni")} // Set active link
                style={{
                  fontFamily: "Montserrat",
                  color: activeLink === "alumni" ? "yellow" : "antiquewhite",
                  fontWeight: activeLink === "alumni" ? "600" : "",
                  padding: activeLink === "alumni" ? "10px 20px" : "10px 20px",
                  backgroundColor: activeLink === "alumni" ? "Blue" : "",
                  borderRadius: activeLink === "alumni" ? "15px" : "",
                }} // Link styling with conditional color
              >
                Alumni
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/about"
                onClick={() => handleLinkClick("about")} // Set active link
                style={{
                  fontFamily: "Montserrat",
                  color: activeLink === "about" ? "yellow" : "antiquewhite",
                  fontWeight: activeLink === "about" ? "600" : "",
                  padding: activeLink === "about" ? "10px 20px" : "10px 20px",
                  backgroundColor: activeLink === "about" ? "Blue" : "",
                  borderRadius: activeLink === "about" ? "15px" : "",
                }} // Link styling with conditional color // Link styling with conditional color
              >
                About
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/news-events"
                onClick={() => handleLinkClick("news-events")} // Set active link
                style={{
                  fontFamily: "Montserrat",
                  color: activeLink === "news-events" ? "yellow" : "antiquewhite",
                  fontWeight: activeLink === "news-events" ? "600" : "",
                  padding: activeLink === "news-events" ? "10px 20px" : "10px 20px",
                  backgroundColor: activeLink === "news-events" ? "Blue" : "",
                  borderRadius: activeLink === "news-events" ? "15px" : "",
                }} // Link styling with conditional color // Link styling with conditional color
              >
                News and Events
              </Nav.Link>
              {isAuthenticated && (
                <Nav.Link
                  as={Link}
                  to="/alumniform" // New form route
                  onClick={() => handleLinkClick("alumniform")} // Set active link
                  style={{
                    fontFamily: "Montserrat",
                    color: activeLink === "alumniform" ? "yellow" : "antiquewhite",
                    fontWeight: activeLink === "alumniform" ? "600" : "",
                    padding: activeLink === "alumniform" ? "10px 20px" : "10px 20px",
                    backgroundColor: activeLink === "alumniform" ? "Blue" : "",
                    borderRadius: activeLink === "alumniform" ? "15px" : "",
                  }} // Link styling with conditional color
                >
                  Alumni Form
                </Nav.Link>
              )}
              {isAuthenticated && (
                <Nav.Link
                  as={Link}
                  to="/profile"
                  onClick={() => handleLinkClick("profile")} // Set active link
                  style={{
                    fontFamily: "Montserrat",
                    color: activeLink === "profile" ? "yellow" : "antiquewhite",
                    fontWeight: activeLink === "profile" ? "600" : "",
                    padding: activeLink === "profile" ? "10px 20px" : "10px 20px",
                    backgroundColor: activeLink === "profile" ? "Blue" : "",
                    borderRadius: activeLink === "profile" ? "15px" : "",
                  }} // Link styling with conditional color
                >
                  Profile
                </Nav.Link>
              )}
            </Nav>
            <Nav>
              {isAuthenticated ? (
                <Nav.Link as={Link} to="/">
                  <button
                    onClick={() =>
                      logout({
                        logoutParams: { returnTo: window.location.origin },
                      })
                    }
                    style={{
                      fontFamily: "Montserrat",
                      backgroundColor: "rgb(174, 106, 106)",
                      color: "antiquewhite",
                      border: "none",
                      padding: "10px 25px",
                      borderRadius: "20px",
                      cursor: "pointer",
                      transition: "all 0.3s ease",
                    }} // Logout button styling with transition
                  >
                    Log Out
                  </button>
                </Nav.Link>
              ) : (
                <Nav.Link as={Link} to="">
                  <button
                    onClick={() => loginWithRedirect()}
                    style={{
                      fontFamily: "Montserrat",
                      backgroundColor: "rgb(174, 106, 106)",
                      color: "antiquewhite",
                      border: "none",
                      padding: "10px 25px",
                      borderRadius: "20px",
                      cursor: "pointer",
                      transition: "all 0.3s ease",
                    }} // Login button styling with transition
                  >
                    Join us
                  </button>
                </Nav.Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavigationBar;
